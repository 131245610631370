import { Account } from '../user/account.model'

export class TokenParser {

    /**
     * Parse jwt to an actual account
     * @param jwt.
     * @returns {Account} - new account.
     */
    public static parse(jwt): Account {
        const decodedToken = TokenParser.decodeToken(jwt)
        const groups = TokenParser.getAuthorities(decodedToken['groups'])
        const lidlGroups = TokenParser.getAuthorities(decodedToken['groupMembership']).filter((e) => e.startsWith('lidl-baustandards'))
        const authorities = [...groups, ...lidlGroups]
        return {
            login: decodedToken['uid'],
            email: decodedToken['mail'],
            authorities
        }
    }

    /**
     * Decode base64 encrypted token.
     * @param jwt - decoded data.
     */
    public static decodeToken(jwt) {
        const tokenComponents = jwt.split(/\./g)
        const payload = tokenComponents[1]
        let decodedPayload
        try {
            // https://jira.schwarz/browse/FXKSTA-2960
            decodedPayload = atob(payload.replace(/-/g, '+').replace(/_/g, '/'))
        } catch (err) {
            throw new Error('Could not parse the payload. Error: ' + err)
        }

        let parsedToken
        try {
            parsedToken = JSON.parse(decodedPayload)
        } catch (err) {
            throw new Error('Could not parse the json body.')
        }

        return parsedToken
    }

    /**
     * Checks if the authorities are coming like a string or array.
     *
     * @param groups - authorities.
     * @returns {string[]} - parsed authorities.
     */
    private static getAuthorities(groups): string[] {
        // Handle missing groups in the token.
        if (!groups) {
            return []
        }

        return Array.isArray(groups) ? TokenParser.getAuthoritiesFromArray(groups) : TokenParser.getAuthoritiesFromString(groups)
    }

    /**
     * Parse string representation of authorities:
     * "groups": "[cn=baustandards-superadmin, ou=baustandards]"
     * or "groups": "cn=baustandards-superadmin".
     *
     * @param groups - authorities.
     * @returns {string[]} - parsed authorities.
     */
    private static getAuthoritiesFromString(groups): string[] {
        return groups
            .replace('[', '')
            .replace(']', '')
            .trim()
            .split(/,\s*/g)
            .filter((authority) => authority.startsWith('cn='))
            .map((authority) => authority.replace('cn=', '').trim())
    }

    /**
     * Parse array representation of authorities:
     * "groups": ["cn=baustandards-superadmin", "ou=baustandards"]
     *
     * @param groups - authorities.
     * @returns {string[]} - parsed authorities.
     */
    private static getAuthoritiesFromArray(groups): string[] {
        return groups
            .map((group) => {
                const groupPath = group.split(/,\s*/)

                if (groupPath.length === 0) {
                    return ''
                } else {
                    return groupPath[0]
                }

            })
            .filter((authority) => authority.startsWith('cn='))
            .map((authority) => authority.replace('cn=', '').trim())
    }
}
