import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit } from '@angular/core'
import { combineLatest, Observable, of } from 'rxjs'
import { switchMap, filter } from 'rxjs/operators'
import { RoomService } from './room.service'
import { Store, select } from '@ngrx/store'
import { Room } from '../../entities/room.model'
import { SelectRoombookAction } from '../redux/actions/book-navigation.action'
import { BookState } from '../redux/book.state'
import { ToggleSidebarAction, SetGalleryDataAction, SetPdfDataAction } from '../redux/actions/release.action'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap'
import { StateStorageService, TitleService } from '../../shared'
import { PdfCollectorService } from '../common/pdf-collector.service'
import { shouldShowSidebar, selectRouteParams } from '../redux/state'
import { contentContainerRequest$ } from '../common/content-container-request'
import { StopLoadingAction, StartLoadingAction } from '../redux/actions/loading.action'
import { ProfileEnvironmentEnum } from '../../layouts/profiles/profile.service'
import { MaterialCategory } from '../../entities/material-category.model'
import { Material } from '../../entities'
import { Release } from '../../entities/release.model'
import { BaseComponent } from '../base/base.component'

@UntilDestroy()
@Component({
    selector: 'room-detail',
    templateUrl: './room-detail.component.html',
    styleUrls: ['./room-detail.component.scss']
})
export class RoomDetailComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    public room: Room

    public isModernization = false

    public hasImages = false

    public hasLinkedAttachmentCategories = false

    public hasMaterialCategories = true

    public hasFiles = false

    public activeIds: string[] = []

    public showSidebar$: Observable<boolean>

    public language: string

    public bookElement = 'material'

    public isLidl: boolean = this.stateStorageService.getProfileEnvironment() === ProfileEnvironmentEnum.Lidl

    public materialsCategoryInformation: Map<MaterialCategory, Material[]>

    @ViewChildren(NgbAccordion) accordions !: QueryList<NgbAccordion>

    constructor(
        private readonly roomService: RoomService,
        store: Store<BookState>,
        private readonly titleService: TitleService,
        private readonly pdfCollectorService: PdfCollectorService,
        private readonly stateStorageService: StateStorageService
    ) {
        super(store)
    }

    ngOnInit(): void {
        super.ngOnInit()
        this.showSidebar$ = this.store.pipe(select(shouldShowSidebar))

        this.store.pipe(
            select(selectRouteParams),
            filter((params) => !!params),
            untilDestroyed(this)
        ).subscribe((params) => {
            this.isModernization = params.construction === 'modernization'
        })

        contentContainerRequest$(this.store)
            .pipe(
                switchMap(([params, currentRelease, selectedRelease]) => {
                    if (params.id && currentRelease.id) {
                        const roomId = parseInt(params.id, 10)
                        const currentReleaseId = currentRelease.id
                        this.language = params.language

                        // We only need to call the backend if the room has actually changed or a release is selected
                        if (!this.room || roomId !== this.room.originalId || selectedRelease) {

                            if (selectedRelease && selectedRelease.id !== currentReleaseId) {
                                return this.loadData(currentReleaseId, roomId, selectedRelease)
                            } else {
                                return this.loadData(currentReleaseId, roomId)
                            }

                        } else {
                            this.titleService.setTitle(this.room.heading)
                        }
                    }
                    return of(this.room)
                }),
                untilDestroyed(this)

            ).subscribe((room: Room) => {
                this.handleRoom(room)

            })
    }

    ngOnDestroy(): void {
        this.store.dispatch(new StopLoadingAction())
    }

    ngAfterViewInit() {

        combineLatest([this.showSidebar$, this.accordions.changes]).pipe(untilDestroyed(this)).subscribe(([showSidebar, accordions]) => {

            setTimeout(() => {
                accordions.forEach((accordion) => {

                    if (!showSidebar) {
                        accordion.closeOtherPanels = false
                        accordion.expandAll()
                    } else {
                        accordion.closeOtherPanels = true
                    }
                })
            }, 0)

        })

    }

    loadData(currentReleaseId: number, materialId: number, selectedRelease?: Release) {
        if (selectedRelease && selectedRelease.id !== currentReleaseId) {
            const response = this.loadRoom(currentReleaseId, materialId, selectedRelease.id)
            response.subscribe((room) => {
                this.loadMaterialsRelated(currentReleaseId, materialId)
            })
            return response
        } else {
            const response = this.loadRoom(currentReleaseId, materialId)
            response.subscribe((room) => {
                this.loadMaterialsRelated(currentReleaseId, materialId)
            })
            return response
        }
    }

    private loadRoom(releaseId: number, originalId: number, selectedReleaseId?: number) {

        this.store.dispatch(new StopLoadingAction())
        this.store.dispatch(new StartLoadingAction())

        return this.roomService
            .find(releaseId, originalId, selectedReleaseId)
    }

    handleRoom(room: Room) {
        this.room = room
        this.titleService.setTitle(room.heading)

        this.hasImages = this.room.images.length > 0
        this.hasLinkedAttachmentCategories = this.room.linkedAttachmentCategories.length > 0
        this.hasFiles = this.room.pdfs.length > 0 || this.room.files.length > 0

        this.store.dispatch(new SelectRoombookAction(room))
        this.store.dispatch(new SetGalleryDataAction(this.room.images))

        const files = this.pdfCollectorService.collectPdfs(this.room)
        this.store.dispatch(new SetPdfDataAction(files))

        this.store.dispatch(new StopLoadingAction())

        this.setInitiallyOpenedPanel()
    }

    private loadMaterialsRelated(releaseId: number, originalId: number) {
        this.roomService.getMaterialsRelated(releaseId, originalId).subscribe((response) => {
            this.materialsCategoryInformation = new Map<MaterialCategory, Material[]>()
            response.forEach((material) => {
                if (Array.isArray(material.materialCategories)) {
                    material.materialCategories.forEach((mc) => {
                        if (this.materialsCategoryInformation.has(mc)) {
                            this.materialsCategoryInformation.get(mc).push(material)
                        } else {
                            this.materialsCategoryInformation.set(mc, [material])
                        }
                    })
                }
            })
        })
    }

    toggleSidebar() {
        this.store.dispatch(new ToggleSidebarAction())
    }

    setInitiallyOpenedPanel(): void {
        this.activeIds = []
        if (this.hasMaterialCategories) {
            this.activeIds = ['ngb-panel-material-categories']
        } else if (this.hasImages) {
            this.activeIds = ['ngb-panel-images']
        } else if (this.hasLinkedAttachmentCategories) {
            this.activeIds = ['ngb-panel-attachment-categories' + 0]
        } else if (this.hasFiles) {
            this.activeIds = ['ngb-panel-files']
        }
    }
}
